import {
  isKarrotLocalCountryCode,
  KarrotLocalCountryCode,
} from "@karrotmarket-com/core";
import type { MetaDescriptor } from "@remix-run/node";

import { parseKarrotLang } from "../../shared/parseKarrotLang";

type CountryCodeUrlMap = {
  [countryCode in KarrotLocalCountryCode]: string;
};

const BASE_URL_MAP: CountryCodeUrlMap = {
  [KarrotLocalCountryCode.US]: "https://www.karrotmarket.com",
  [KarrotLocalCountryCode.CA]: "https://www.karrotmarket.com/ca",
  [KarrotLocalCountryCode.UK]: "https://www.karrotmarket.com/uk",
  [KarrotLocalCountryCode.JP]: "https://www.karrotmarket.com/jp",
  [KarrotLocalCountryCode.KR]: "https://www.daangn.com/kr",
};

export function hreflangMeta({
  path,
}: {
  path: Partial<CountryCodeUrlMap>;
}): MetaDescriptor[] {
  const baseUrls = Object.entries(BASE_URL_MAP);

  return baseUrls.map(([countryCode, baseUrl]) => {
    if (!isKarrotLocalCountryCode(countryCode)) {
      return {};
    }

    const karrotLang = parseKarrotLang(countryCode);

    return {
      tagName: "link",
      rel: "alternate",
      hrefLang: karrotLang,
      href: baseUrl + path[countryCode],
    };
  });
}
